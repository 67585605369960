import * as React from "react";

export function Webinar() {
  return (
    <div>
      <section id="lesson-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col colmn-1 col-lg-6 col-md-6 col-12 order-lg-1 order-2">
              <div className="img-wrapper text-lg-left text-center">
                <img
                  className="img-fluid"
                  src="./assets/images/illo-video.png"
                  alt="illo"
                  width={586}
                />
              </div>
            </div>
            <div className="col colmn-2 col-lg-6 col-md-6 col-12 order-lg-2 order-1 mb-lg-0 mb-5 mx-auto text-left">
              <div
                className="title-area mb-3 w-100"
                style={{ maxWidth: "525px" }}
              >
                <h6
                  className="sub-heading text-white mb-1"
                  style={{ opacity: "0.7" }}
                >
                  VIDEOREEL LESSON #1
                </h6>
                <h3 className="text-white font-weight-normal">
                  Join Us for our LIVE Webinar.
                </h3>
              </div>
              <div className="desc w-100 mb-4" style={{ maxWidth: "570px" }}>
                <p
                  className="md-txt text-white"
                  style={{ lineHeight: "1.56", fontWeight: 500 }}
                >
                  Join us for a private training that will walk you through how
                  to use VideoReel to help you create and sell videos for
                  maximum engagement, views and profits. This training or
                  upgrade will not be made available afterwards. Sign up and
                  make sure to show up on this live training with all your
                  questions.
                </p>
              </div>
              <div className="btn-wrapper">
                <a
                  href="https://videoreel.io/training"
                  target="_blank"
                  className="btn btn-2 accnt-mngmt-btn d-flex align-items-center justify-content-center"
                >
                  Sign Up Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Webinar;
