import React, { useState, useEffect } from "react";
import styled, { isStyledComponent } from "styled-components";
import {connect, useDispatch} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import WhiteLabelNav from "./WhiteLabelNav";
import { useHistory } from "react-router-dom";
import {
  setSmtpData,
  clearWhiteLabelData,
  getWhiteLabelUsers,
} from "../../actions/whiteLabel";
import axios from "axios";
import { baseURL } from "../../global/global";
import {setAlert} from "../../actions/alert";
import Alert from "../alert/Alert";

const SMTP = ({
  user,
  loading,
  whiteLabel,
  setSmtpData,
  clearWhiteLabelData,
  getWhiteLabelUsers,
}) => {
  const [accountType, setAccountType] = useState([]);
  const [smtp, setSmtp] = useState({
    host: "",
    username: "",
    password: "",
    port: "",
    sent_from: "",
  });
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const [verifySmtp, setVerifySmtp] = useState(false);
  const [smtpLoader, setSmtpLoader] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);
  const [isEdit,setIsEdit] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (
      !(
        Object.keys(whiteLabel.smtp).length === 0 &&
        whiteLabel.smtp.constructor === Object
      )
    ) {
      setSmtp({ ...whiteLabel.smtp });
    }
  }, [whiteLabel.smtp]);

  useEffect(() => {
    if (whiteLabel.whiteLabelData.length > 0) {
      setSmtp({ ...whiteLabel.whiteLabelData[0].smtp });
      setVerifySmtp(true);
      setIsEdit(true);
    }
  }, [whiteLabel.whiteLabelData]);

  const setAllSmtpValue = (e) => {
    let target = e.target;
    setVerifySmtp(false);
    setSmtp((value) => ({ ...value, [target.name]: target.value }));
  };

  const sendSmtpData = async () => {
    setSmtpData(smtp);
    setSaveStatus(true);
  };

  const sendAllDataToBackend = async () => {
    setLoader(true);

    let price = whiteLabel.price.map((value) => ({
      id: value.id,
      name: value.name,
      price: value.price,
      type: value.type
    }));
    let payment = whiteLabel.payment.map((value) => ({
      mem_id: value.mem_id,
      type: value.payment,
      button_code: value.button_code,
      secret_key: "",
      product_id: "",
      ipn_url: "",
    }));

    // const formData = new FormData();

    // formData.append("general", whiteLabel.general);
    // formData.append("price", price);
    // formData.append("payment", payment);
    // formData.append("smtp", whiteLabel.smtp);
    // formData.append("user_id", user.user_id);
    // formData.append("template_id", whiteLabel.template_id);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      general: { ...whiteLabel.general },
      price: price,
      payment: payment,
      smtp: whiteLabel.smtp,
      user_id: user.user_id,
      template_id: whiteLabel.template_id,
    });

    try {

      let res;
      if(isEdit===true) {
        res = await axios.post(`${baseURL}api/edit-domain`, body, config);
      }else{
        res = await axios.post(`${baseURL}api/add-domain`, body, config);
      }

      if (res.data.status === true) {
        setLoader(false);
        clearWhiteLabelData();
        getWhiteLabelUsers({ user_id: user.user_id });
        dispatch(setAlert(res.data.message,'success'));
        history.push("/whitelabel");
      } else if (res.data.status === false) {
        setLoader(false);
        history.push("/whitelabel");
        dispatch(setAlert(res.data.message,'danger'));
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  const checkSmtp = async () =>{
    setSmtpLoader(true);
    let data = smtp;

    const res = await axios.post(
        `${baseURL}/api/verify-smtp`,
        data
    );
    if (res.data.status === true) {
      dispatch(setAlert(res.data.message,'success'));
      setVerifySmtp(true);
      setSmtpData(smtp);
    }else{
      dispatch(setAlert(res.data.message,'danger'));
      setVerifySmtp(false);
    }
    setSmtpLoader(false);

  };

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <Alert/>
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"smtp"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important"}}
                >
                  SMTP Details
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-10">
                    <div className="row">
                      <div className="col-6">
                        <a style={{
                          float: "left",
                          color : "#1cb7f2"
                        }}
                           className="mb-1 pl-xl-2  mt-md-1"
                           title="Edit"
                           data-toggle="collapse"
                           data-parent="#accordion1"
                           href="#showCpanel"
                        >Finding Your Email Settings In cPanel:</a>
                      </div>

                    </div>

                    <div className="row mt-md-2">
                      <div className="col-6">
                        <div className="collapse in" id="showCpanel">
                          <p>Login into your cPanel.</p>
                          <p>Go to > Mail Email Accounts.</p>
                          <p>Navigate to your email and select “Connect Devices”.</p>
                          <p className="note"><b>Note: If there is no email account listed, you will need to create one.</b></p>
                          <p>You can find the settings under Mail Client Manual Settings.</p>
                          <p>Please note that different versions of cPanel can have different appearances. As a general rule, you should always use the SSL/TLS settings when configuring your email.</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="collapse in" id="showWeb">
                          <p>If you do not have cPanel access, you can still view your email settings through webmail. Simply follow these steps:</p>
                          <p>Log into Webmail using your email address and password credentials.</p>
                          <p><b>NOTE: You do not need to select a Webmail application.</b></p>
                          <p><b>NOTE: You can still access your settings if you automatically log into your default webmail application. Click on the email account in the top right. Then, select Configure Mail Client from the drop-down menu options.</b></p>
                          <p>Scroll down to the Mail Client Manual Settings section.</p>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      {/* <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Domain Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="domain"
                            placeholder="Enter your Domain Name"
                            value={smtp.domain}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div> */}

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Host Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="host"
                            placeholder="Enter your Host Name"
                            value={smtp.host}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Username </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="username"
                            placeholder="Enter your Username"
                            value={smtp.username}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Password </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="password"
                            name="password"
                            placeholder="Enter your Password"
                            value={smtp.password}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Port </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="port"
                            placeholder="Enter your Port No."
                            value={smtp.port}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Sent From </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="sent_from"
                            placeholder="Sent From"
                            value={smtp.sent_from}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      {/*{saveStatus === false ? <div className="col-11 col-md-8 col-lg-4 text-center mt-3">*/}
                      {/*  {loader ? (*/}
                      {/*    <button*/}
                      {/*      className="btn btn-3 accnt-mngmt-btn mt-4"*/}
                      {/*      type="button"*/}
                      {/*      disabled*/}
                      {/*      style={{*/}
                      {/*        background: "#1cb7f2",*/}
                      {/*        font: "16px",*/}
                      {/*        color: "white",*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <span*/}
                      {/*        className="spinner-border spinner-border-sm text-light mx-2"*/}
                      {/*        role="status"*/}
                      {/*        aria-hidden="true"*/}
                      {/*      ></span>*/}
                      {/*      <strong>Loading...</strong>*/}
                      {/*    </button>*/}
                      {/*  ) : (*/}
                      {/*    <button*/}
                      {/*      className="btn btn-3 accnt-mngmt-btn mt-4"*/}
                      {/*      onClick={sendSmtpData}*/}
                      {/*      disabled={*/}
                      {/*        smtp.host === "" ||*/}
                      {/*        smtp.sent_from === "" ||*/}
                      {/*        smtp.username === "" ||*/}
                      {/*        smtp.password === "" ||*/}
                      {/*        smtp.port === ""*/}
                      {/*          ? true*/}
                      {/*          : false*/}
                      {/*      }*/}
                      {/*    >*/}
                      {/*      Save*/}
                      {/*    </button>*/}
                      {/*  )}*/}
                      {/*</div> : ''}*/}

                      {/*{*/}
                      {/*  saveStatus === true ?*/}
                      {/*        <div className="col-11 col-md-8 col-lg-4 text-center mt-3">*/}
                      {/*          <Link*/}
                      {/*              to="/preview-details"*/}
                      {/*              className="btn btn-3 accnt-mngmt-btn mt-4"*/}
                      {/*              style={{*/}
                      {/*                background: "#1cb7f2",*/}
                      {/*                font: "16px",*/}
                      {/*                color: "white",*/}
                      {/*              }}*/}
                      {/*          > Preview Details </Link>*/}
                      {/*        </div>*/}
                      {/*      : ''*/}
                      {/*}*/}
                      <div className="col-10">

                      </div>

                      { verifySmtp === true ?
                          <div className="col-2 text-right">
                        {loader ? (
                            <button
                                className="btn btn-3 accnt-mngmt-btn mt-4"
                                type="button"
                                disabled
                                style={{
                                  background: "#1cb7f2",
                                  font: "16px",
                                  color: "white",
                                }}
                            >
                            <span
                                className="spinner-border spinner-border-sm text-light mx-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                              <strong>Loading...</strong>
                            </button>
                        ) : (
                            <button
                                className="btn btn-3 accnt-mngmt-btn mt-4"
                                onClick={sendAllDataToBackend}
                                disabled={
                                  smtp.host === "" ||
                                  smtp.sent_from === "" ||
                                  smtp.username === "" ||
                                  smtp.password === "" ||
                                  smtp.port === "" ||
                                  (Object.keys(whiteLabel.smtp).length === 0 &&
                                      whiteLabel.smtp.constructor === Object)
                                      ? false
                                      : false
                                }
                            >
                              Save
                            </button>
                        )}
                      </div>
                          : ''
                      }

                      {
                        verifySmtp === false ?
                        <div className="col-2 text-right">
                          <button
                              className="btn btn-3 accnt-mngmt-btn mt-4"
                              type="button"
                              onClick={checkSmtp}
                              disabled={
                                smtp.host === "" ||
                                smtp.sent_from === "" ||
                                smtp.username === "" ||
                                smtp.password === "" ||
                                smtp.port === ""
                              }
                              style={{
                                font: "16px",
                              }}
                          > { smtpLoader ? "Verifying... " : "Verify SMTP" }</button>
                        </div>
                            : ''
                      }

                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, {
  setSmtpData,
  clearWhiteLabelData,
  getWhiteLabelUsers,
})(SMTP);
