import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";
import { connect } from "react-redux";
import {
  deleteClientTeamAccount,
  editClientTeamAccount,
} from "../../actions/accountManagement";
import { setLoader } from "../../actions/loader";
import ResetPassword from "./ResetPassword";
import { removeAlert } from "../../actions/alert";

const ClientManagement = ({
  client,
  userGenId,
  deleteClientTeamAccount,
  editClientTeamAccount,
  loader,
  setLoader,
  showAlert,
}) => {
  const [clientsInfo, setClientsInfo] = useState({
    firstName: "",
    email: "",
    password: "",
  });

  const [modal, setModal] = useState({
    isOpen: false,
  });

  const [passwordModal, setPasswordModal] = useState({
    open: false,
    appName: "client",
  });

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setClientsInfo({
      password: "",
      firstName: client.name ? client.name : "",

      email: client.email ? client.email : "",
    });
  }, [client]);

  // Closing Delete Modal
  const close = () => {
    setModal({ isOpen: false });
  };

  const editClientInfo = (e) => {
    setClientsInfo({
      ...clientsInfo,
      [e.target.name]: e.target.value,
    });
  };

  // Opening password modal
  const handleOpen = () => {
    removeAlert();
    setPasswordModal({
      ...modal,
      open: true,
      appName: "client",
    });
  };

  // Closing password modal
  const handleClose = () => {
    setPasswordModal({
      ...modal,
      open: false,
    });
  };

  // Delete Account
  const deleteConfimAccount = async () => {
    const data = {
      user_id: userGenId,
      account_id: client.id,
      type: "client",
    };

    await deleteClientTeamAccount(data);

    close();
  };

  // Edit Account
  const updateClientAccount = async () => {
    setCheck(true);
    setLoader();
    showAlert(true);
    const data = {
      id: client.id,
      email: clientsInfo.email,
      password: clientsInfo.password,
      Firstname: clientsInfo.firstName.split(" ")[0],
      Lastname: clientsInfo.firstName.split(" ").slice(1)
        ? clientsInfo.firstName.split(" ").slice(1).join(" ")
        : "",
      type: "client",
    };

    await editClientTeamAccount(data, userGenId);
    setCheck(false);
  };

  return (
    <>
      <tr className="accordion-toggle collapsed" id="accordion1">
        <td className="td-br">{client.name}</td>
        <td className="text-overflow td-br">{client.email}</td>
        <td className="td-br">Client Account</td>
        <td className="td-br">{client.created}</td>
        <td className="expand-button">
          <a
            className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
            title="Edit"
            data-toggle="collapse"
            data-parent="#accordion1"
            href={`#${client.id}client`}
          >
            <svg width="20px" height="20px" viewBox=" 0 0 20 20">
              <use xlinkHref="./assets/images/edit.svg#edit-ico" />{" "}
            </svg>
          </a>
          <Link
            to="#"
            className="action-icon lock-icon remove mb-1 pl-xl-2  mt-md-1"
            data-placement="top"
            data-toggle="tooltip"
            title="Reset Password"
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 31.7 36"
              data-toggle="modal"
              data-target="#reset-password"
              onClick={() => handleOpen()}
            >
              <use xlinkHref="./assets/images/lock.svg#ico-lock" />
            </svg>
          </Link>
          <Link
            to="#"
            className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
            data-toggle="modal"
            data-target="#delete-user"
            title="Delete User"
            onClick={() => {
              removeAlert();
              setModal({ isOpen: true });
            }}
          >
            <svg
              width="20px"
              className="mt-sm-md-icon"
              height="20px"
              viewBox=" 0 0 20 20"
              data-placement="top"
              data-toggle="tooltip"
              title="Delete User"
            >
              <use xlinkHref="./assets/images/delete.svg#delete-ico" />
            </svg>
          </Link>
        </td>
      </tr>
      <tr className="hide-table-padding" style={{ height: "0px !important" }}>
        <td className="d-none" />
        <td colSpan={12}>
          <div
            id={`${client.id}client`}
            className="collapse in p-3"
            style={{ width: "100%" }}
          >
            <div className="col-12">
              <p className="text-white"></p>
              <div className="mt-2 mb-0">
                <h6
                  className="text-white text-left mb-4 mt-3"
                  style={{
                    fontSize: "18px !important",
                    marginLeft: "-10px",
                    textAlign: "left !important",
                  }}
                >
                  Edit Client Member
                </h6>
                <div>
                  <form>
                    <div className="row d-flex justify-content-center ">
                      <div className="col col-lg-6 col-12 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label-pos">Name</label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="firstName"
                            placeholder="Name"
                            required
                            value={clientsInfo.firstName}
                            onChange={(e) => editClientInfo(e)}
                          />
                        </div>
                      </div>

                      <div className="col col-lg-6 col-12 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label-pos">
                            Email Address{" "}
                          </label>
                          <input
                            className="form-control input-dark-accnt personal-info"
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required
                            value={clientsInfo.email}
                            onChange={(e) => editClientInfo(e)}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col col-lg-6 col-12 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label-pos">
                            Account Type{" "}
                          </label>
                          <input
                            className="form-control input-dark-accnt personal-info"
                            type="text"
                            name="Account Type"
                            placeholder="Account Type"
                            required
                            value="Client"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col col-md-12 text-center mt-5 mb-5">
                  <button
                    className="btn btn-2 accnt-mngmt-btn mr-xl-2 mb-2"
                    title="Edit"
                    data-toggle="collapse"
                    data-parent="#accordion1"
                    href={`#${client.id}client`}
                  >
                    Cancel
                  </button>
                  {loader && check ? (
                    <button
                      className="btn btn-3 accnt-mngmt-btn mb-2"
                      type="button"
                      disabled
                      style={{
                        background: "#1cb7f2",
                        font: "16px",

                        color: "white",
                      }}
                    >
                      <span
                        className="spinner-border spinner-border-sm text-light mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <strong>Updating...</strong>
                    </button>
                  ) : (
                    <button
                      onClick={updateClientAccount}
                      className="btn btn-3 accnt-mngmt-btn mb-2"
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          {modal.isOpen ? (
            <ConfirmDelete close={close} confirm={deleteConfimAccount} />
          ) : null}
        </td>
        <td>
          {passwordModal.open ? (
            <ResetPassword
              close={handleClose}
              appType={passwordModal.appName}
              user_id={userGenId}
              id={client.id}
              showAlert={showAlert}
            />
          ) : null}
        </td>
      </tr>
    </>
  );
};

ClientManagement.propTypes = {
  client: PropTypes.object.isRequired,
  userGenId: PropTypes.string,
  deleteClientTeamAccount: PropTypes.func.isRequired,
  editClientTeamAccount: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  setLoader: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  deleteClientTeamAccount,
  editClientTeamAccount,
  setLoader,
  removeAlert,
})(ClientManagement);
