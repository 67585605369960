import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {connect, useDispatch, useSelector} from "react-redux";
import { setTemplateIdData } from "../../actions/whiteLabel";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import Alert from "../alert/Alert";

const PreviewDetails = () => {

    const general = useSelector(state => state.whiteLabel.general);
    const price = useSelector(state => state.whiteLabel.price);
    const payment = useSelector(state => state.whiteLabel.payment);
    const smtp = useSelector(state => state.whiteLabel.smtp);
    const template = useSelector(state => state.whiteLabel.template_id);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const [loader,setLoader] = useState(false);

    const sendWhitelabelData = async () => {
        setLoader(true);
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const body = JSON.stringify({
            general: general,
            price: price,
            payment: payment,
            smtp: smtp,
            user_id: user.user_id,
            template_id: template,
        });

        try {
            const res = await axios.post(`${baseURL}api/add-domain`, body, config);

            if (res.data.status === true) {
                console.log(res);
            } else if (res.data.status === false) {
                console.log(res);
                dispatch(setAlert(res.data.message,'danger'));
            }
        } catch (err) {
            console.log(err);
        }

        setLoader(false);

    };



    return (
        <ContractStyle>
            <div className="contract-assets">
                <section id="contract-assets-content">
                    <Alert/>
                    <div className="container">
                        <div className="row justify-content-center align-items-between fb-contract mt-5">
                            <div className="col-11 mb-4">
                                <h6
                                    className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                                    style={{ fontSize: "18px !important" }}
                                >
                                    Preview Details
                                </h6>
                                <div className="row bg-table justify-content-center">

                                    <div className="col-12">
                                        <p><b>Domain Details :</b></p>
                                        <table className="table table-border">
                                            <thead>
                                                <tr>
                                                    <th>Domain</th>
                                                    <th>Logo</th>
                                                    <th>Name</th>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th>Support</th>
                                                    <th>TXT Record</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbody-bg">
                                                <tr>
                                                    <td>{general.name}</td>
                                                    <td><img src={general.logo} style={{width: "40px"}} /></td>
                                                    <td>{general.app_name}</td>
                                                    <td>{general.title}</td>
                                                    <td><textarea className="form-control" value={general.description} style={{width: "180px", height: "50px", margin: "auto"}} readOnly>{general.description}</textarea></td>
                                                    <td>{general.support}</td>
                                                    <td><textarea className="form-control" value={general.txt_record} style={{width: "180px", height: "50px", margin: "auto"}} readOnly>{general.txt_record}</textarea></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr/>
                                    <div className="col-12">
                                        <p><b>Price Details :</b></p>
                                        <table className="table table-border">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Type</th>
                                            </tr>
                                            </thead>
                                            <tbody className="tbody-bg">
                                            {
                                                Object.values(price).map(value=>(
                                                <tr>
                                                    <td>{value.name}</td>
                                                    <td>{value.price}</td>
                                                    <td>{value.type}</td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-12">
                                        <p><b>Payment Details :</b></p>
                                        <table className="table table-border">
                                            <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Button Code</th>
                                            </tr>
                                            </thead>
                                            <tbody className="tbody-bg">
                                            {
                                                Object.values(payment).map(value=>(
                                                    <tr>
                                                        <td>{value.type}</td>
                                                        <td><textarea className="form-control" value={value.button_code} style={{width: "100%", height: "50px", margin: "auto"}} readOnly>{value.button_code}</textarea></td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-12">
                                        <p><b>SMTP Details :</b></p>
                                        <table className="table table-border">
                                            <thead>
                                            <tr>
                                                <th>Host</th>
                                                <th>Username</th>
                                                <th>Password</th>
                                                <th>Port</th>
                                                <th>Sent From</th>
                                            </tr>
                                            </thead>
                                            <tbody className="tbody-bg">
                                            <tr>
                                                <td>{smtp.host}</td>
                                                <td>{smtp.username}</td>
                                                <td>{smtp.password}</td>
                                                <td>{smtp.port}</td>
                                                <td>{smtp.sent_from}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div className="col-2">
                                        <Link to="/smtp" className="btn btn-3 accnt-mngmt-btn mt-4" >Back</Link>
                                    </div>

                                    <div className="col-2">
                                        {
                                            loader === false ?
                                            <button type="button" onClick={sendWhitelabelData}
                                                    className="btn btn-3 accnt-mngmt-btn mt-4">Submit</button>
                                            :
                                                <button
                                                    className="btn btn-3 accnt-mngmt-btn mt-4"
                                                    type="button"
                                                    disabled
                                                    style={{
                                                        background: "#1cb7f2",
                                                        font: "16px",
                                                        color: "white",
                                                    }}
                                                >
                                                <span
                                                    className="spinner-border spinner-border-sm text-light mx-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                    <strong>Loading...</strong>
                                                </button>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ContractStyle>
    );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }

  #overlay {
    height: 0%;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: hidden;
    transition: 0.5s;
  }

  #overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .temp_video:hover #overlay {
    height: 100%;
    position: realtive;
  }
`;

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
    whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, { setTemplateIdData })(PreviewDetails);
