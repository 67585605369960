import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {connect, useDispatch, useSelector} from "react-redux";
import { setTemplateIdData } from "../../actions/whiteLabel";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseURL} from "../../global/global";
import {deleteWhitelabel} from "../../actions/accountManagement";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";

const ViewDetails = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const [whitelabel,setWhitelabel] = useState(false);
    const [modal, setModal] = useState({
        isOpen: false,
    });

    const close = () => {
        setModal({ isOpen: false });
    };

    const deleteConfirmAccount = async () => {
        const data = {
            user_id: user.user_id
        };

        await dispatch(deleteWhitelabel(data));

        close();
    };

    useEffect(()=>{

        axios({
            method: 'POST',
            url: `${baseURL}api/domain-details`,
            data: {user_id: user.user_id}
        }).then(res=>{
            console.log(res);
            if(res.data.status === true) {
                setWhitelabel(res.data.data[0]);
            }else{
                setWhitelabel(false);
            }
        }).catch(error=>{
            console.log(error);
            setWhitelabel(false);
        });
    },[]);

    return (
        <ContractStyle>
            <div className="contract-assets">
                <section id="contract-assets-content">
                    <div className="container">
                        <div className="row justify-content-center align-items-between fb-contract mt-5">
                            <div className="col-11 mb-4">
                                <h6
                                    className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                                    style={{ fontSize: "18px !important" }}
                                >
                                    View Details
                                </h6>
                                <div className="row bg-table justify-content-center">
                                    {
                                        whitelabel === false ?
                                            <div className="col-4 text-center">
                                                <Link to="/whitelabel" className="btn btn-3 accnt-mngmt-btn mt-4">
                                                    Add Whitelebel Domain
                                                </Link>
                                            </div>
                                            :
                                         <>
                                             <div className="col-12 text-center">
                                                 <img src={whitelabel.general.logo} style={{width: "150px"}}/>
                                             </div>
                                            <div className="col-12">
                                                <h5>Domain Details :</h5>
                                                <table className="table table-border">
                                                    <thead>
                                                        <tr>
                                                            <th>Domain</th>
                                                            <th>Name</th>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th>Support Link</th>
                                                            <th>TXT Record</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="tbody-bg">
                                                    <tr>
                                                        <td>{whitelabel.general.name}</td>
                                                        <td>{whitelabel.general.app_name}</td>
                                                        <td>{whitelabel.general.title}</td>
                                                        <td>
                                                            <textarea className="form-control" value={whitelabel.general.description} style={{width: "180px", height: "25px", margin: "auto"}} readOnly>{whitelabel.general.description}</textarea>
                                                        </td>
                                                        <td>{whitelabel.general.support}</td>
                                                        <td>
                                                            <textarea className="form-control" value={whitelabel.general.txt_record} style={{width: "180px", height: "25px", margin: "auto"}} readOnly>{whitelabel.general.txt_record}</textarea>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                             <div className="col-4">
                                                 <h5>Price Details :</h5>
                                                 <table className="table table-border">
                                                     <thead>
                                                     <tr>
                                                         <th>Name</th>
                                                         <th>Price</th>
                                                     </tr>
                                                     </thead>
                                                     <tbody className="tbody-bg">
                                                         {
                                                             Object.values(whitelabel.price).map(value=>(

                                                                     <tr>
                                                                         <td>{value.name}</td>
                                                                         <td>{value.price}</td>
                                                                     </tr>

                                                             ))
                                                         }
                                                     </tbody>
                                                 </table>
                                             </div>
                                             <div className="col-8">
                                                 <h5>Payment Details :</h5>
                                                 <table className="table table-border">
                                                     <thead>
                                                     <tr>
                                                         <th>Type</th>
                                                         <th>Button Code</th>
                                                     </tr>
                                                     </thead>
                                                     <tbody className="tbody-bg">
                                                     {
                                                         Object.values(whitelabel.payment).map(value=>(

                                                             <tr>
                                                                 <td>{value.payment === 'Pksipn' ? 'Pay Kick Start' : value.payment}</td>
                                                                 <td><textarea className="form-control" value={value.button_code} style={{width: "100%", height: "20px", margin: "auto"}} readOnly>{value.button_code}</textarea></td>
                                                             </tr>

                                                         ))
                                                     }
                                                     </tbody>
                                                 </table>
                                             </div>
                                             <div className="col-12">
                                                 <h5>SMTP Details :</h5>
                                                 <table className="table table-border">
                                                     <thead>
                                                     <tr>
                                                         <th>Host</th>
                                                         <th>Username</th>
                                                         <th>Password</th>
                                                         <th>Port</th>
                                                         <th>Sent From</th>
                                                     </tr>
                                                     </thead>
                                                     <tbody className="tbody-bg">
                                                     <tr>
                                                         <td>{whitelabel.smtp.host}</td>
                                                         <td>{whitelabel.smtp.username}</td>
                                                         <td>{whitelabel.smtp.password}</td>
                                                         <td>{whitelabel.smtp.port}</td>
                                                         <td>{whitelabel.smtp.sent_from}</td>
                                                     </tr>
                                                     </tbody>
                                                 </table>
                                             </div>
                                             <div className="col-2">
                                                 <Link to="/whitelabel" className="btn btn-3 accnt-mngmt-btn mt-4">Edit</Link>
                                             </div>
                                             <div className="col-2">
                                                 <button
                                                     data-toggle="modal"
                                                     data-target="#delete-user"
                                                     title="Delete User"
                                                     onClick={() => {
                                                         setModal({ isOpen: true });
                                                     }}
                                                     style={{width: "inherit"}}
                                                     type="button"
                                                     className="btn btn-delete accnt-mngmt-btn mt-4"
                                                 >
                                                     Delete
                                                 </button>
                                             </div>

                                             {modal.isOpen ? (
                                                 <ConfirmDelete message={'Created White Label app & it\'s users will be deleted'} close={close} confirm={deleteConfirmAccount} />
                                             ) : null}
                                         </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ContractStyle>
    );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }

  #overlay {
    height: 0%;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: hidden;
    transition: 0.5s;
  }

  #overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .temp_video:hover #overlay {
    height: 100%;
    position: realtive;
  }
`;

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
    whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, { setTemplateIdData })(ViewDetails);
