import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import video from "./video";
import loader from "./loader";
import social from "./social";
import accountManagement from "./accountManagement";
import loadingTemplate from "./loadingTemplate";
import dashboardData from "./dashboard";
import seekValue from "./seekValue";
import ttsValue from "./tts";
import supportData from "./support";
import normalTemplates from "./normalTemplates";
import brandingdata from "./branding";
import whiteLabel from "./whiteLabel";

export default combineReducers({
  auth,
  alert,
  video,
  loader,
  social,
  accountManagement,
  loadingTemplate,
  dashboardData,
  seekValue,
  ttsValue,
  supportData,
  normalTemplates,
  brandingdata,
  whiteLabel,
});
