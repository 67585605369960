import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Notification from "./Notification";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

export function Notifications({ auth: { user, loading } }) {
  const [is_client, setIsClient] = useState(false);
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Helmet>
        <title>VideoReel | Notifications</title>
      </Helmet>
      <section
        id="proj-title-bar"
        className="bg-profile pt-5 pb-3"
        style={{ minHeight: "80vh" }}
      >
        <div
          className="container-fluid px-4 text-center pt-4"
          style={{ marginTop: "82px" }}
        >
          <div className="col-12 d-flex justify-content-center">
            <form
              action="#"
              method="post"
              className="d-block w-100 mr-2"
              style={{ maxWidth: "453px" }}
            >
              <div className="input-group">
                <input
                  id="search"
                  type="search"
                  name="search"
                  className="form-control input-search"
                  placeholder=" Search"
                />
                <div className="input-group-prepend">
                  <svg
                    width="18px"
                    height="18px"
                    viewBox=" 0 0 18 18"
                    className="input-search-svg-ico"
                  >
                    <use xlinkHref="./assets/images/search.svg#search-ico" />
                  </svg>
                </div>
              </div>
            </form>
          </div>
          <div className="mb-5" />
          <div className="container">
            <div className="row bg-dark" style={{ minHeight: "453px" }}>
              {/* remove d-none class from id "no-reults" to show user that no results found*/}
              <div className="col-12 no-results" id="no-results">
                <img
                  src="./assets/images/notifications.png"
                  className="img-fluid"
                  alt=""
                />
                <p className="text-white w-100 mb-0 pb-0">No results found.</p>
                <p className="text-white w-100 mt-0 pt-1">
                  Please try a different search term.
                </p>
              </div>
              <div className="table-responsive table-p d-none" id="results">
                <table className="table text-white mb-0 table-border-btm table-notification">
                  <thead className="d-none">
                    <tr>
                      <th style={{ width: "95%", textAlign: "left" }}>
                        Describition
                      </th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Notification />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mby-100" />
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Notifications);
