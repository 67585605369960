import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const DfyArticles = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("dfy"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  const firstCol = [
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip",
      value: 1,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip",
      value: 2,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack3.zip",
      value: 3,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip",
      value: 4,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip",
      value: 5,
    },
  ];

  const secondCol = [
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip",
      value: 6,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip",
      value: 7,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip",
      value: 8,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack9.zip",
      value: 9,
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip",
      value: 10,
    },
  ];

  return (
    <DfyStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract py-5">
              <BusinessNav />
              <div className="col-9 mb-5 ">
                <h6
                  className="text-white mb-0 mt-5 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  DFY Articles
                </h6>
                <div className="row bg-table justify-content-center mt-2">
                  <div className="col-6">
                    <div className="row">
                      {firstCol.map((value) => (
                        <div className="col-10 mt-3">
                          <div className="card fb-card border-0">
                            <div className="card-body">
                              <a
                                className="btn btn-3 accnt-mngmt-btn p-2"
                                style={{ fontSize: "0.9rem" }}
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location = value.src;
                                }}
                              >
                                Article Pack {value.value}
                              </a>
                            </div>
                            <div className="card-footer p-0"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      {secondCol.map((value) => (
                        <div className="col-10 mt-3">
                          <div className="card fb-card border-0">
                            <div className="card-body">
                              <a
                                className="btn btn-3 accnt-mngmt-btn p-2"
                                style={{ fontSize: "0.9rem" }}
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location = value.src;
                                }}
                              >
                                Article Pack {value.value}
                              </a>
                            </div>
                            <div className="card-footer p-0"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </DfyStyle>
  );
};

const DfyStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(DfyArticles);
