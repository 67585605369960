import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import Plyr from "plyr-react";
// import "plyr-react/dist/plyr.css";

import ReactPlayer from "react-player";

const VideoPart = ({ templateVideo, seekValue }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [player, setPlayer] = useState(null);
  const videoRef = useRef({});

  useEffect(() => {
    setVideoUrl(templateVideo ? templateVideo.preview_video_url : "");
  }, [templateVideo]);

  useEffect(() => {
    if (videoRef) {
      setPlayer(videoRef.current);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (player) {
      if (player.seekTo) {
        if (seekValue.seekValue > 0) {
          player.seekTo(parseFloat(seekValue.seekValue));
        }
      }
    }
  }, [seekValue, player]);

  // useEffect(() => {
  //   if (videoUrl !== "") {
  //     console.log("raj");
  //     player.source = {
  //       type: "video",
  //       sources: [
  //         {
  //           src: videoUrl
  //             ? videoUrl.includes(baseURL)
  //               ? videoUrl
  //               : `${baseURL}${videoUrl}`
  //             : "",
  //           type: "video/mp4",
  //           size: 720,
  //         },
  //       ],
  //     };
  //   }
  // }, [player, videoUrl]);
  return (
    <>
      {videoUrl !== "" ? (
        <div className="video-editor">
          <ReactPlayer
            // playing
            ref={videoRef}
            url={videoUrl ? videoUrl : ""}
            controls
            width="100%"
            height="100%"
          />
        </div>
      ) : null}
    </>
  );
};

VideoPart.propTypes = {
  templateVideo: PropTypes.object,
};

export default VideoPart;
