import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const FbAssets = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("business"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <FbStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <BusinessNav />
              <div className="col-9 mb-5 ">
                <h6
                  className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Facebook Ads Templates
                </h6>

                <div className="row bg-table justify-content-center align-items-center">
                  <div className="col-8">
                    <div className="card fb-card border-0">
                      <div
                        className="card-header"
                        style={{ background: "#1cb7f2" }}
                      >
                        <h5 className="text-light">Facebook Ads Templates</h5>
                      </div>
                      <div className="card-body">
                        Download this done-for-you Facebook and Instagram ad
                        creatives and ad copy templates that can easily be
                        customized to be used for any niche
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                </div>

                <div className="row bg-table justify-content-center align-items-center">
                  <div className="col-5 mt-2">
                    <div className="card fb-card border-0">
                      <div className="card-header text-center">
                        Download your Facebook Ads Templates here
                      </div>
                      <div className="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn p-2"
                          style={{ fontSize: "0.9rem" }}
                          href="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location =
                              "https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip";
                          }}
                        >
                          Download the Templates
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </FbStyle>
  );
};

const FbStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }
  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(FbAssets);
