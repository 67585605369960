import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {connect, useDispatch, useSelector} from "react-redux";
import { setTemplateIdData } from "../../actions/whiteLabel";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseURL} from "../../global/global";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";
import {deleteClientTeamAccount} from "../../actions/accountManagement";

const WhiteLabelUsers = () => {

    const dispatch =  useDispatch();
    const user = useSelector(state => state.auth.user);
    const [whitelabel,setWhitelabel] = useState([]);
    const [userId,setUserId] = useState();
    const [modal, setModal] = useState({
        isOpen: false,
    });

    const close = () => {
        setModal({ isOpen: false });
    };

    const editWhitelabel = (e,id) => {
        let target = e.target;
        setWhitelabel(value=>value.map(val=> {
            return val.id === id ?
                {...val,[target.name]:target.value}:{...val}

        }));
    };

    const updateWhitelabel = (e,id) => {
      e.preventDefault();

      let temp_user = whitelabel.map(value=>(
         value.id === id ? value.name : ''
      ));

      if(temp_user !== ''){
         axios({
             method: 'POST',
             url: `${baseURL}api/edit-whitelabel-user`,
             data: {name: temp_user[0], id: id}
         }).then(res=>{
             console.log(res);
         }).catch(error=>{
            console.log(error);
         });
      }

    };

    const deleteConfirmAccount = async () => {
        const data = {
            user_id: user.user_id,
            account_id: userId
        };

        await dispatch(deleteClientTeamAccount(data));
        close();
    };

    useEffect(()=>{
        axios({
            method: 'POST',
            url: `${baseURL}api/fetch-whitelabel-users`,
            data: {user_id: user.user_id}
        }).then(res=>{
            console.log(res);
            if(res.data.status === true){
                setWhitelabel(res.data.data);
            }
        }).catch(error=>{
            console.log(error);
        });
    },[]);


    return (
        <ContractStyle>
            <div className="contract-assets">
                <section id="contract-assets-content">
                    <div className="container">
                        <div className="row justify-content-center align-items-between fb-contract mt-5">
                            <div className="col-11 mb-4">
                                <h6
                                    className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                                    style={{ fontSize: "18px !important" }}
                                >
                                    Preview Details
                                </h6>
                                <div className="row bg-table justify-content-center">
                                    <div className="col-12">
                                        <table className="table table-border">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Created By</th>
                                                    <th>Account Type</th>
                                                    <th>Created</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbody-bg">
                                                {
                                                    whitelabel.length > 0 ?
                                                    whitelabel.map(value=>(
                                                        <>
                                                        <tr key={value.id}>

                                                            <td>{value.name}</td>
                                                            <td>{value.email}</td>
                                                            <td>{value.created_by}</td>
                                                            <td>{value.memberships}</td>
                                                            <td>{value.created}</td>
                                                            <td>
                                                                <a
                                                                    className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
                                                                    title="Edit"
                                                                    data-toggle="collapse"
                                                                    data-parent="#accordion1"
                                                                    href={`#${value.id}whitelabel`}
                                                                >
                                                                    <svg width="20px" height="20px" viewBox=" 0 0 20 20">
                                                                        <use xlinkHref="./assets/images/edit.svg#edit-ico" />{" "}
                                                                    </svg>
                                                                </a>
                                                                <Link
                                                                    to="#"
                                                                    className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
                                                                    data-toggle="modal"
                                                                    data-target="#delete-user"
                                                                    title="Delete User"
                                                                    onClick={() => {
                                                                        setUserId(value.id)
                                                                        setModal({ isOpen: true });
                                                                    }}
                                                                >
                                                                    <svg
                                                                        width="20px"
                                                                        className="mt-sm-md-icon"
                                                                        height="20px"
                                                                        viewBox=" 0 0 20 20"
                                                                        data-placement="top"
                                                                        data-toggle="tooltip"
                                                                        title="Delete User"
                                                                    >
                                                                        <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                                                                    </svg>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        <tr className="hide-table-padding" style={{ height: "0px !important" }}>
                                                            <td className="d-none" />
                                                            <td colSpan={12}>
                                                                <div id={`${value.id}whitelabel`}
                                                                     className="collapse in p-3"
                                                                     style={{ width: "100%" }}>
                                                                    <form>
                                                                        <div className="row d-flex justify-content-center ">
                                                                            <div className="col col-lg-6 col-12 p-1">
                                                                                <div className="form-group form-group-mb">
                                                                                    <label className="input-label-pos">Name</label>
                                                                                    <input
                                                                                        className="form-control input-dark-accnt personal-info  w-100"
                                                                                        type="text"
                                                                                        name="name"
                                                                                        placeholder="Name"
                                                                                        required
                                                                                        value={value.name}
                                                                                        onChange={(e) => editWhitelabel(e,value.id)}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col col-lg-6 col-12 p-1">
                                                                                <div className="form-group form-group-mb">
                                                                                    <label className="input-label-pos">
                                                                                        Email Address{" "}
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control input-dark-accnt personal-info"
                                                                                        type="email"
                                                                                        name="email"
                                                                                        placeholder="Email Address"
                                                                                        required
                                                                                        value={value.email}
                                                                                        readOnly
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col col-lg-6 col-12 p-1">
                                                                                <div className="form-group form-group-mb">
                                                                                    <label className="input-label-pos">
                                                                                        Account Type{" "}
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control input-dark-accnt personal-info"
                                                                                        type="text"
                                                                                        name="membership"
                                                                                        placeholder="Account Type"
                                                                                        required
                                                                                        value={value.memberships}
                                                                                        readOnly
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    <div className="row d-flex justify-content-center">
                                                                    <div className="col col-md-2 text-center mt-5 mb-5">
                                                                        <a
                                                                            className="btn btn-2 accnt-mngmt-btn mr-xl-2 mb-2"
                                                                            title="Edit"
                                                                            data-toggle="collapse"
                                                                            data-parent="#accordion1"
                                                                            href={`#${value.id}whitelabel`}
                                                                            style={{color: "#1cb7f2"}}
                                                                        >
                                                                            Cancel
                                                                        </a>
                                                                    </div>
                                                                    <div className="col col-md-2 text-center mt-5 mb-5">
                                                                        <button
                                                                            className="btn btn-2 accnt-mngmt-btn mb-2"
                                                                            type="button"
                                                                            style={{
                                                                                background: "#1cb7f2",
                                                                                font: "16px",
                                                                                color: "white",
                                                                            }}
                                                                            onClick={(e)=>updateWhitelabel(e,value.id)}
                                                                        >
                                                                           Update
                                                                        </button>

                                                                    </div>
                                                                    </div>
                                                                    </form>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                            {modal.isOpen ? (
                                                                <ConfirmDelete message={'Created White Label app & it\'s users will be deleted'} close={close} confirm={deleteConfirmAccount} />
                                                            ) : null}
                                                        </>

                                                    ))
                                                    : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ContractStyle>
    );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }

  #overlay {
    height: 0%;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: hidden;
    transition: 0.5s;
  }

  #overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .temp_video:hover #overlay {
    height: 100%;
    position: realtive;
  }
`;

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
    whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, { setTemplateIdData })(WhiteLabelUsers);
