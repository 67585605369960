import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AvatarModal from "./AvatarModal";
import editImg from "../../assets/images/editBt.png"

function AvatarUpdate({ user, loading }) {
  const [avatarData, setAvatarData] = useState({
    profile_pic: "",
    name: "",
    account_type: [],
  });

  const [modal, setModal] = useState({
    isOpen: false,
  });

  const [is_client, setIsClient] = useState(false);
  const [is_team, setIsTeam] = useState(false);

  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
      setIsTeam(loading || !user.is_team ? false : user.is_team);
    }
  }, [user, loading]);

  useEffect(
    () =>
      setAvatarData({
        profile_pic: loading || !user.profile_pic ? "" : `${user.profile_pic}`,
        name: loading || !user.name ? "" : user.name,
        account_type: loading || !user.account_type ? [] : user.account_type,
      }),
    [loading, user]
  );

  const modalOpen = (val) => {
    setModal({
      isOpen: true,
    });
  };

  const modalClose = () => {
    setModal({
      isOpen: false,
    });
  };

  return (
    <>
      <div className="container-fluid px-4 text-center mt-1 pt-4">
        <div className="proH">
          <div className="proH-main">
          <img
            src={
              avatarData.profile_pic
                ? avatarData.profile_pic
                : "./assets/images/empty_profile.png"
            }
            alt="avatar"
            className="img-fluid profile-page-avatar"
          />
          <div className="imgEdit"
          style={{ cursor: "pointer" }}
          onClick={modalOpen}
          >
            <img src={editImg} />
          </div>
          </div>
          {/* <img src="./assets/images/dummyavatar.png" class="profile-page-avatar dummy-avatar mb-2" alt=""> */}
        </div>
        <h4 className="text-center text-white  mb-2 profile-user-name">
          {avatarData.name}
        </h4>
        <button className="membership-level-text mb-5">
          {is_client
            ? "Client"
            : is_team
            ? "Team"
            : avatarData.account_type.includes("bundle")
            ? "bundle"
            : avatarData.account_type.join(", ")}
        </button>
      </div>

      {/* Modal */}
      {modal.isOpen ? <AvatarModal close={modalClose} /> : null}
    </>
  );
}

AvatarUpdate.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(AvatarUpdate);
