import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function Logo({ brandingData }) {
  const [logo, setLogo] = useState("");

  console.log(brandingData.brandingdata.reseller,"logodata")

  useEffect(() => {
    if (brandingData.brandingdata.reseller) {
      console.log("first")
      setLogo(
        !brandingData.loading || !brandingData.brandingdata.reseller
          ? brandingData.brandingdata.reseller.logo
          : ""
      );
    }
    else {setLogo("")}
  }, [brandingData.brandingdata]);

  return (
    <>
      <Link className="navbar-brand p-0" to="/dashboard">
        <img
          className="img-fluid videoreel-favicon-icon"
          src={`${logo !== "" ? logo : "./assets/images/video-reel.png"}`}
          alt="Logo"
        />

        {/* <img className="img-fluid videoreel-favicon-icon" src={require("src/assets/images/video-reel.svg")} alt="App Logo" /> */}
      </Link>
    </>
  );
}

const mapStateToProps = (state) => ({
  brandingData: state.brandingdata,
});

export default connect(mapStateToProps)(Logo);
