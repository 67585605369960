import React from "react";
import Helmet from "react-helmet";
import AvatarUpdate from "./AvatarUpdate";
import PersonalInformation from "./PersonalInformation";
import ResetPassword from "./ResetPassword";
import Alert from "../alert/Alert";
import { connect } from "react-redux";
import { removeAlert } from "../../actions/alert";
import PropTypes from "prop-types";

const Profile = ({ removeAlert }) => {
  return (
    <div>
      <Helmet>
        <title>VideoReel | Profile</title>
      </Helmet>

      <section
        id="proj-title-bar"
        className="bg-profile"
        style={{ paddingTop: "100px" }}
      >
        <Alert />
        <AvatarUpdate />
        <div className="pb-3" />
      </section>
      <section id="project-section" style={{ backgroundColor: "#171717" }}>
        <PersonalInformation />
        <ResetPassword />
      </section>
    </div>
  );
};

Profile.propTypes = {
  removeAlert: PropTypes.func.isRequired,
};

export default connect(null, { removeAlert })(Profile);
