import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { fetchAllProject } from "../../actions/video";
import { useHistory, withRouter } from "react-router-dom";
import { baseURL } from "../../global/global";
import Alert from "../alert/Alert";
import axios from "axios";
import { removeAlert, setAlert } from "../../actions/alert";
import { connect } from "react-redux";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";
import Loader from "../loader/Loader";
import PublishModal from "../publishModal/PublishModal";

function Video({
  auth: { user, loading },
  fetchAllProject,
  project,
  setAlert,
  removeAlert,
}) {
  const [user_id, setUserId] = useState("");
  const [projects, setProjects] = useState([]);
  const [renderStatusData, setRenderStatusData] = useState({
    renderProgress: 0,
    message: "",
  });
  const [renderStatus, setRenderStatus] = useState(false);
  const [renderId, setRenderId] = useState("");

  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [publishModal, setPublishModal] = useState({
    isOpen: false,
    video_url: "",
  });
  const [deleteData, setDeleteData] = useState({});
  const [is_client, setIsClient] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  useEffect(() => {
    if (user_id) {
      fetchAllProject({ user_id });
    }
  }, [user_id, fetchAllProject]);

  useEffect(() => {
    setProjects(project.allProject);
  }, [project.allProject, project.loading]);

  // Fetching All Projects again and again
  useEffect(() => {
    let local = null;
    if (projects) {
      const check = projects.find(
        (item) =>
          item.render_status === "Added to Queue" ||
          item.render_status === "Downloading Resources"
      );

      if (check) {
        local = setInterval(() => fetchAllProject({ user_id }), 8000);
      } else {
        clearInterval(local);
      }
    }

    return () => {
      if (local) {
        clearInterval(local);
      }
    };
  }, [projects, user_id, fetchAllProject]);

  // Set true for hit rendering status
  useEffect(() => {
    if (projects) {
      const check = projects.find(
        (item) =>
          item.render_status === "Rendering" ||
          item.render_status === "Awaiting Rendering"
      );

      if (check) {
        setRenderId(check.project_id);
        setRenderStatus(true);
      }
    }
  }, [projects]);

  // Close delete modal
  const close = () => {
    setModal({ isOpen: false });
  };

  // Fetching rendering status
  const fetchProjectStatus = useCallback(
    () => async (data, source, interval) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify(data);

      try {
        const res = await axios.post(
          `${baseURL}fetch-project-status`,
          body,
          { cancelToken: source.token },
          config
        );

        if (res.data.status === true) {
          if (
            res.data.message === "Rendering" ||
            res.data.message === "Awaiting Rendering" ||
            res.data.message === "Added to Queue" ||
            res.data.message === "Downloading Resources"
          ) {
            setRenderStatusData(res.data);
          } else if (res.data.message === "Success") {
            setRenderStatus(false);
            fetchAllProject({ user_id });
            clearInterval(interval);
          }
        } else {
          setRenderStatus(false);
          fetchAllProject({ user_id });
          clearInterval(interval);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    [user_id, fetchAllProject]
  );

  useEffect(() => {
    let interval = null;
    let source = axios.CancelToken.source();
    if (renderStatus === true) {
      interval = setInterval(() => {
        fetchProjectStatus()({ project_id: renderId }, source, interval);
      }, 5000);
    }

    return () => {
      if (interval) {
        source.cancel("Cancel");
        clearInterval(interval);
      }
    };
  }, [renderStatus, renderId, fetchProjectStatus]);

  // delete project by modal

  const deleteProjectByModal = () => {
    deleteProject(deleteData);
  };

  const deleteProject = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(`${baseURL}delete-project`, body, config);

      if (res.data.status === true) {
        fetchAllProject({ user_id });
        setAlert(res.data.message, "success");
      } else {
        setAlert(res.data.message, "danger");
      }
      close();
    } catch (err) {
      console.log(err.response);
    }
  };

  // reRender Project
  const reRender = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(data);
    try {
      const res = await axios.post(
        `${baseURL}re-render-user-project`,
        body,
        config
      );
      if (res.data.status === true) {
        fetchAllProject({ user_id });
        setAlert(res.data.message, "success");
      } else {
        setAlert(res.data.message, "danger");
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const dateToYMD = (date) => {
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = date.getDate();
    var m = strArray[date.getMonth() - 1];
    var y = date.getFullYear();
    return "" + (d <= 9 ? "0" + d : d) + " " + m + ", " + y;
  };

  return (
    <>
      <Alert />
      {project.loading ? (
        <>
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "40vh" }}
          >
            <Loader />
          </div>
        </>
      ) : projects.length <= 0 ? (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ height: "40vh" }}
        >
          <h4>
            You have not created any video yet. Click "Create Videos" to start
            creating stunning videos
          </h4>
        </div>
      ) : (
        projects.slice(0, 8).map((project) =>
          project.render_status === "Rendering" ||
          project.render_status === "Awaiting Rendering" ? (
            <React.Fragment key={project.project_id}>
              <div className="col colmn-2 col-xl-3 col-md-6 col-sm-9 col-12 px-2 mb-lg-0 mb-5 mt-3">
                <div className="card-wrapper">
                  <div className="status status-rendring-failed w-100 d-flex align-items-center justify-content-center">
                    <img
                      className="img-fluid status-img"
                      src="./assets/images/vr-mark.png"
                      alt=""
                    />
                    <div
                      className={`md-txt text-white font-italic mb-0 ${
                        renderStatusData.message !== "Awaiting Rendering" ||
                        project.render_status !== "Awaiting Rendering"
                          ? "mt-4"
                          : ""
                      }`}
                      style={{
                        position: "relative",
                        top: `${
                          renderStatusData.message !== "Awaiting Rendering" ||
                          project.render_status !== "Awaiting Rendering"
                            ? "25px"
                            : "10px"
                        }`,
                        width: "15rem",
                      }}
                    >
                      {renderStatusData.message === "Rendering" ||
                      project.render_status === "Rendering" ? (
                        <div
                          className="progress position-relative bg-light"
                          style={{ height: "0.75rem" }}
                        >
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuenow={
                              project.renderProgress >=
                                renderStatusData.renderProgress ||
                              renderStatusData.renderProgress === 0
                                ? project.renderProgress
                                : renderStatusData.renderProgress > 100
                                ? 100
                                : renderStatusData.renderProgress
                            }
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: `${
                                project.renderProgress >=
                                  renderStatusData.renderProgress ||
                                renderStatusData.renderProgress === 0
                                  ? project.renderProgress
                                  : renderStatusData.renderProgress > 100
                                  ? 100
                                  : renderStatusData.renderProgress
                              }%`,
                              backgroundColor: "#007bff",
                            }}
                          >
                            {project.renderProgress >=
                              renderStatusData.renderProgress ||
                            renderStatusData.renderProgress === 0
                              ? project.renderProgress
                              : renderStatusData.renderProgress > 100
                              ? 100
                              : renderStatusData.renderProgress}
                            %
                          </div>
                        </div>
                      ) : null}
                      <p
                        className="md-txt text-white font-italic mb-0 mt-3"
                        style={{ fontWeight: 500 }}
                      >
                        {renderStatusData.message === ""
                          ? project.render_status
                          : renderStatusData.message}
                      </p>
                    </div>
                    <div className="actions-icon row align-items-center mx-0">
                      {is_client === true ? null : (
                        <>
                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon edit-icon mb-1"
                              onClick={(e) => {
                                e.preventDefault();
                                return history.push(
                                  `/${
                                    project.category === "legacy" ||
                                    project.category === "normal"
                                      ? "preview-editor"
                                      : "editor"
                                  }?project_id=${project.project_id}`
                                );
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Edit</p>
                          </div>

                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon delete-icon remove mb-1"
                              data-toggle="modal"
                              data-target="#confirm-del"
                              onClick={(e) => {
                                e.preventDefault();
                                removeAlert();
                                setDeleteData({
                                  project_id: project.project_id,
                                  user_id: user_id,
                                });
                                setModal({ isOpen: true });
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Delete</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="details-wrapper row mx-0">
                    <div className="meta-wrapper flex-grow-1">
                      <div className="date mb-1 d-flex justify-content-between">
                        <span className="sm-txt text-white p-1">
                          {project.project_name}
                        </span>
                        <span className="sm-txt text-white text-right p-1">
                          {dateToYMD(
                            new Date(
                              parseInt(project.created_on.split("-")[0]),
                              parseInt(project.created_on.split("-")[1]),
                              parseInt(project.created_on.split("-")[2])
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : project.render_status === "Added to Queue" ||
            project.render_status === "Downloading Resources" ? (
            <React.Fragment key={project.project_id}>
              <div className="col colmn-2 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-xl-0 mb-5 mt-3">
                <div className="card-wrapper">
                  <div className="status transcribing w-100 d-flex align-items-center justify-content-center">
                    <img
                      className="img-fluid status-img"
                      src="./assets/images/vr-mark-grey.svg"
                      alt=""
                    />
                    <p
                      className="md-txt font-italic mb-0"
                      style={{ fontWeight: 500 }}
                    >
                      {project.render_status === "Added to Queue"
                        ? "Added to Queue"
                        : "Downloading Resources"}
                    </p>
                    <div className="actions-icon row align-items-center mx-0">
                      {is_client === true ? null : (
                        <>
                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon edit-icon mb-1"
                              onClick={(e) => {
                                e.preventDefault();
                                return history.push(
                                  `/${
                                    project.category === "legacy" ||
                                    project.category === "normal"
                                      ? "preview-editor"
                                      : "editor"
                                  }?project_id=${project.project_id}`
                                );
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Edit</p>
                          </div>

                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon delete-icon remove mb-1"
                              data-toggle="modal"
                              data-target="#confirm-del"
                              onClick={(e) => {
                                e.preventDefault();
                                removeAlert();
                                setDeleteData({
                                  project_id: project.project_id,
                                  user_id: user_id,
                                });
                                setModal({ isOpen: true });
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Delete</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="details-wrapper row mx-0">
                    <div className="meta-wrapper flex-grow-1">
                      <div className="date mb-1 d-flex justify-content-between">
                        <span className="sm-txt text-white p-1">
                          {project.project_name}
                        </span>
                        <span className="sm-txt text-white text-right p-1">
                          {dateToYMD(
                            new Date(
                              parseInt(project.created_on.split("-")[0]),
                              parseInt(project.created_on.split("-")[1]),
                              parseInt(project.created_on.split("-")[2])
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : project.render_status === "Failed" ? (
            <React.Fragment key={project.project_id}>
              <div
                key={project.project_id}
                className="col colmn-3 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-lg-0 mb-5 mt-3"
              >
                <div className="card-wrapper">
                  <div
                    className="status transcribing w-100 d-flex align-items-center justify-content-center"
                    style={{
                      backgroundImage:
                        "linear-gradient(to bottom, #ff7e73 -11%, #c90618 39% , #a2000f 59%,#a2000f 78%)",
                    }}
                  >
                    <img
                      className="img-fluid status-img"
                      alt=""
                      src="./assets/images/vr-mark-grey.svg"
                    />
                    <p
                      className="md-txt font-italic mb-0 text-white"
                      style={{ fontWeight: 500 }}
                    >
                      Rendering Failed
                    </p>
                    <div className="actions-icon row align-items-center mx-0">
                      {is_client === true ? null : (
                        <>
                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon edit-icon mb-1"
                              onClick={(e) => {
                                e.preventDefault();
                                return history.push(
                                  `/${
                                    project.category === "legacy" ||
                                    project.category === "normal"
                                      ? "preview-editor"
                                      : "editor"
                                  }?project_id=${project.project_id}`
                                );
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Edit</p>
                          </div>

                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon retry-icon mb-1"
                              onClick={(e) => {
                                e.preventDefault();
                                reRender({
                                  project_id: project.project_id,
                                  type: "reRender",
                                });
                              }}
                            >
                              <svg
                                className="retry-failed"
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/retry.svg#retry-ico" />
                              </svg>
                            </a>
                            <p
                              className="sm-txt text-white"
                              style={{ color: "#fff !important" }}
                            >
                              Rerender
                            </p>
                          </div>
                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon delete-icon remove mb-1"
                              data-toggle="modal"
                              data-target="#confirm-del"
                              onClick={(e) => {
                                e.preventDefault();
                                removeAlert();
                                setDeleteData({
                                  project_id: project.project_id,
                                  user_id: user_id,
                                });
                                setModal({ isOpen: true });
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Delete</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="details-wrapper row mx-0">
                    <div className="meta-wrapper flex-grow-1">
                      <div className="date mb-1 text-left px-1">
                        <span className="sm-txt text-white ">
                          {project.project_name}
                        </span>
                      </div>
                    </div>
                    <div className="time">
                      <span className="sm-txt text-white">
                        {dateToYMD(
                          new Date(
                            parseInt(project.created_on.split("-")[0]),
                            parseInt(project.created_on.split("-")[1]),
                            parseInt(project.created_on.split("-")[2])
                          )
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : project.render_status === "Awaiting Customization" ? (
            <React.Fragment key={project.project_id}>
              <div
                key={project.project_id}
                className="col colmn-3 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-lg-0 mb-5 mt-3"
              >
                <div className="card-wrapper">
                  <div
                    className="status transcribing w-100 d-flex align-items-center justify-content-center"
                    style={{
                      backgroundImage:
                        "linear-gradient( 150deg, #17d583 -11%, #00d2b3 39%, #5bc1de 59%, #1cb7f2 78% )",
                    }}
                  >
                    <img
                      className="img-fluid status-img"
                      alt=""
                      src="./assets/images/vr-mark.png"
                    />
                    <p
                      className="md-txt text-white font-italic mb-0"
                      style={{ fontWeight: 500 }}
                    >
                      Awaiting Customization
                    </p>
                    <div className="actions-icon row align-items-center mx-0">
                      {is_client === true ? null : (
                        <>
                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon edit-icon mb-1"
                              onClick={(e) => {
                                e.preventDefault();
                                return history.push(
                                  `/${
                                    project.category === "legacy" ||
                                    project.category === "normal"
                                      ? "preview-editor"
                                      : "editor"
                                  }?project_id=${project.project_id}`
                                );
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Edit</p>
                          </div>
                          <div className="icon-wrapper">
                            <a
                              href="!#"
                              className="action-icon delete-icon remove mb-1"
                              data-toggle="modal"
                              data-target="#confirm-del"
                              onClick={(e) => {
                                e.preventDefault();
                                removeAlert();
                                setDeleteData({
                                  project_id: project.project_id,
                                  user_id: user_id,
                                });
                                setModal({ isOpen: true });
                              }}
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox=" 0 0 20 20"
                              >
                                <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                              </svg>
                            </a>
                            <p className="sm-txt text-white">Delete</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="details-wrapper row mx-0">
                    <div className="meta-wrapper flex-grow-1">
                      <div className="date mb-1 text-left px-1">
                        <span className="sm-txt text-white ">
                          {project.project_name}
                        </span>
                      </div>
                    </div>
                    <div className="time">
                      <span className="sm-txt text-white">
                        {dateToYMD(
                          new Date(
                            parseInt(project.created_on.split("-")[0]),
                            parseInt(project.created_on.split("-")[1]),
                            parseInt(project.created_on.split("-")[2])
                          )
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div
              key={project.project_id}
              className="col colmn-3 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-lg-0 mb-5 mt-3"
            >
              <div className="card-wrapper">
                <div className="status w-100 d-flex align-items-center justify-content-center mb-0">
                  <img
                    className="img-fluid proj_img poster"
                    src={`${project.preview_image_url}`}
                    alt=""
                  />
                  <div className="actions-icon row align-items-center mx-0">
                    {is_client === true ? null : (
                      <>
                        <div className="icon-wrapper">
                          <a
                            href="!#"
                            className="action-icon edit-icon mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              return history.push(
                                `/${
                                  project.category === "legacy" ||
                                  project.category === "normal"
                                    ? "preview-editor"
                                    : "editor"
                                }?project_id=${project.project_id}`
                              );
                            }}
                          >
                            <svg width="20px" height="20px" viewBox="0 0 20 20">
                              <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                            </svg>
                          </a>
                          <p className="sm-txt text-white">Edit</p>
                        </div>
                      </>
                    )}

                    <div className="icon-wrapper ">
                      <a
                        href="!#"
                        className="action-icon download-icon mb-1"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = project.output_video;
                        }}
                      >
                        <svg width="20px" height="20px" viewBox=" 0 0 20 20">
                          <use xlinkHref="./assets/images/download.svg#download-ico" />
                        </svg>
                      </a>
                      <p className="sm-txt text-white">Download</p>
                    </div>

                    {is_client === true ? null : (
                      <>
                        <div className="icon-wrapper">
                          <a
                            href="!#"
                            className="action-icon publish-icon mb-1"
                            data-toggle="modal"
                            data-target="#publish"
                            onClick={(e) => {
                              e.preventDefault();
                              setPublishModal({
                                isOpen: true,
                                video_url: project.output_video,
                              });
                            }}
                          >
                            <svg
                              width="20px"
                              height="20px"
                              viewBox=" 0 0 20 20"
                            >
                              <use xlinkHref="./assets/images/publish.svg#publish-ico" />
                            </svg>
                          </a>
                          <p className="sm-txt text-white">Publish</p>
                        </div>
                        <div className="icon-wrapper">
                          <a
                            href="!#"
                            className="action-icon delete-icon remove mb-1"
                            data-toggle="modal"
                            data-target="#confirm-del"
                            onClick={(e) => {
                              e.preventDefault();
                              removeAlert();
                              setDeleteData({
                                project_id: project.project_id,
                                user_id: user_id,
                              });
                              setModal({ isOpen: true });
                            }}
                          >
                            <svg
                              width="20px"
                              height="20px"
                              viewBox=" 0 0 20 20"
                            >
                              <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                            </svg>
                          </a>
                          <p className="sm-txt text-white">Delete</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="details-wrapper row mx-0">
                  <div className="meta-wrapper flex-grow-1">
                    <div className="date mb-1 text-left px-1">
                      <span className="sm-txt text-white ">
                        {project.project_name}
                      </span>
                    </div>
                  </div>
                  <div className="time">
                    <span className="sm-txt text-white">
                      {dateToYMD(
                        new Date(
                          parseInt(project.created_on.split("-")[0]),
                          parseInt(project.created_on.split("-")[1]),
                          parseInt(project.created_on.split("-")[2])
                        )
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        )
      )}
      {modal.isOpen ? (
        <ConfirmDelete close={close} confirm={deleteProjectByModal} />
      ) : null}

      {publishModal.isOpen ? (
        <PublishModal videoData={publishModal} close={setPublishModal} />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.video,
});

Video.propTypes = {
  auth: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  fetchAllProject: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchAllProject,
  setAlert,
  removeAlert,
})(withRouter(Video));
