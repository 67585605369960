import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EditPart from "./EditPart";
import VideoPart from "./VideoPart";
import Helmet from "react-helmet";
import SideNav from "../SideNav";
import { connect } from "react-redux";
import { loadTemplate, clearTemplate } from "../../../actions/video";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

const Editor = ({
  loadTemplate,
  loadingTemplate: { loading, templateData, languageTranslate },
  clearTemplate,
  location,
  auth,
  seekValue,
}) => {
  const [template, setTemplate] = useState({});
  const [is_client, setIsClient] = useState(false);

  const project_ids = queryString.parse(location.search).project_id;
  const template_ids = queryString.parse(location.search).template_id;
  useEffect(() => {
    if (auth.user) {
      setIsClient(
        auth.loading || !auth.user.is_client ? false : auth.user.is_client
      );
    }
  }, [auth.user, auth.loading]);

  useEffect(() => {
    if (project_ids) {
      loadTemplate({
        id: project_ids,
        name: "project",
      });
    } else if (template_ids) {
      loadTemplate({
        id: template_ids,
        name: "template",
      });
    }
    return () => {
      clearTemplate();
    };
  }, [loadTemplate, clearTemplate, project_ids, template_ids]);

  useEffect(() => {
    setTemplate(!templateData || loading ? [] : templateData[0]);
  }, [templateData, loading]);

  if (project_ids === undefined && template_ids === undefined) {
    return <Redirect to="/create-videos" />;
  }

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Helmet>
        <title>VideoReel | Edit Video</title>
      </Helmet>

      <section id="editor-section" className="bg-dark section-topmargin">
        <div className="container-fluid px-0">
          <div className="row mx-auto">
            <div className="col colmn-1 col-1 px-0 bg-dark">
              <SideNav activeTab={"editor"} />
            </div>
            <div
              className="col colmn-2 col-10 px-0 mx-auto flex-grow-1"
              style={{ maxWidth: "90%", minHeight: "90vh" }}
            >
              <div
                id="editor-tab-cont"
                className="tab-pane fade show active d-flex flex-column justify-content-center align-items-center"
                role="tabpanel"
                aria-labelledby="editor-tab"
                style={{ width: "100%" }}
              >
                <div
                  className="row"
                  style={{
                    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.7)",
                    width: "90%",
                  }}
                >
                  {template && Object.keys(template).length ? (
                    <>
                      <div className="col-4 text-white p-0">
                        <EditPart
                          templateData={template}
                          project_id={project_ids}
                          template_id={template_ids}
                          languageTranslate={languageTranslate}
                        />
                      </div>

                      <div
                        className="col-8 text-white video-part pr-0"
                        style={{
                          height: "100%",
                        }}
                      >
                        <VideoPart
                          templateVideo={template}
                          seekValue={seekValue}
                        />{" "}
                      </div>
                    </>
                  ) : null}
                </div>

                {/* <div className="row m-5 mt-0 w-100 justify-content-center">
                  <div className="col-md-2 text-white h-10">
                  <button type="button" class="btn btn-primary w-100" onClick={()=>childRef.current.raj()}>Save</button>
                  </div>
                  <div className="col-md-2 h-10 text-white">
                  <button type="button" class="btn btn-primary w-100">Render</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingTemplate: state.loadingTemplate,
  auth: state.auth,
  seekValue: state.seekValue,
});

Editor.propTypes = {
  loadTemplate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { loadTemplate, clearTemplate })(
  Editor
);
