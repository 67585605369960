import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {connect, useDispatch} from "react-redux";
import { baseURL } from "../../global/global";
import { removeAlert, setAlert } from "../../actions/alert";
import { getBrandingDataFromServer } from "../../actions/branding";
import { setGeneralData } from "../../actions/whiteLabel";
import axios from "axios";
import Alert from "../alert/Alert";
import { useHistory } from "react-router-dom";
import WhiteLabelNav from "./WhiteLabelNav";
import { SketchPicker } from "react-color";
import Loader from "../loader/Loader";
import { Redirect } from "react-router-dom";
import defaultLogo from '../../assets/images/default_logo.png';

const WhiteLabel = ({ user, loading, whiteLabel, setGeneralData,setAlert,removeAlert }) => {
  const [selectedFile, setSelectedFile] = useState({});
  // const [showColorPicker, setShowColorPicker] = useState({
  //   open: false,
  // });
  const dispatch = useDispatch();

  let randomstring = require("randomstring");

  // const [color, setColor] = useState("");
  const [txt,setTxt] = useState(false);
  const [txtLoad,setTxtLoad] = useState({
    txt_record: false
  });

  const [user_id, setUserId] = useState("");
  const [loader, setLoader] = useState(false);
  const [general, setGeneral] = useState({
    name: "",
    logo: "",
    color: "",
    app_name: "",
    title: "",
    description: "",
    support: "",
    txt_record: "videoreel-verification-code=kTEP4rLaO9TcpqJnM6LPghYndEB1AS1q",
    embedded_code: ""
  });
  const [accountType, setAccountType] = useState([]);
  const history = useHistory();
  // const wrapperRef = useRef(null);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  useEffect(() => {
    if (
      Object.keys(whiteLabel.general).length > 0 &&
      whiteLabel.general.constructor === Object
    ) {
      setGeneral({ ...whiteLabel.general });
    } else if (whiteLabel.whiteLabelData.length > 0) {
      setGeneral({ ...whiteLabel.whiteLabelData[0].general });
      setTxt(true);
    }
  }, [whiteLabel.whiteLabelData, whiteLabel.general]);

  // // Handle click outside ColorPicker Hide
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       setShowColorPicker({ open: false });
  //     }
  //   }

  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [wrapperRef]);

  const setAllGeneralValue = (e) => {
    let target = e.target;
    setGeneral((value) => ({ ...value, [target.name]: target.value }));
  };

  const uploadBranding = async (e) => {
    if (!e.target.files[0]) return;

    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 5) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        setLoader(true);
        setSelectedFile(e.target.files[0]);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        try {
          const res = await axios.post(
            `${baseURL}/api/upload-logo`,
            formData,
            config
          );

          if (res.data.status === true) {
            setGeneral((value) => ({ ...value, logo: res.data.data }));
            setSelectedFile({});
            setLoader(false);
          } else if (res.data.status === false) {
            setLoader(false);
          }
        } catch (err) {
          console.log(err.response);
          setLoader(false);
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(png, jpeg, jpg)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload more then 5MB in size", "danger");
    }
  };

  const setGeneralDataInRedux = () => {
    setLoader(true);

    // setFileData(selectedFile);
    setGeneralData(general);

    setLoader(false);
  };

  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  const checkTxt = async () => {
    setTxtLoad({txt_record: true});
    let data = {domain: general.name, txt_record: general.txt_record};

    const res = await axios.post(
        `${baseURL}/api/verify-txt`,
        data
    );
    if (res.data.status === true) {
      setTxt(true);
      setAlert(res.data.message,'success');
    }else{
      setTxt(false);
      setAlert(res.data.message,'danger');
    }
    setTxtLoad({txt_record: false});
  };

  // Color Chanage
  // const handleChangeComplete = (colorChange) => {
  //   // setBackground(colorChange.hex);
  //   setGeneral((value) => ({ ...value, color: colorChange.hex }));
  // };

  // const popover = {
  //   position: "fixed",
  //   zIndex: "1000",
  //   left: "400px",
  //   top: "240px",
  // };
  // const cover = {
  //   position: "relative",
  //   top: "0px",
  //   right: "0px",
  //   bottom: "0px",
  //   left: "0px",
  // };

  // const swatch = {
  //   boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
  //   cursor: "pointer",
  //   width: "50px",
  //   height: "30px",
  //   borderRadius: "5px",
  // };

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <Alert/>
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"whitelabel"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  General
                </h6>
                <div className="row bg-table mb-3 justify-content-center secHT">
                  <div className="col-10">
                    <div className="row justify-content-center">
                      {" "}
                      <div className="col-4 ml-0 pl-1">
                        {loader === true ? (
                          <Loader />
                        ) : (
                          <img
                            alt=""
                            className="proj_img poster"
                            // src="https://picsum.photos/200/300"
                            src={general.logo !== '' ? general.logo : defaultLogo}
                            // onClick={() =>
                            //   previewVideo(template.preview_video_url)
                            // }
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "20vh",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="col-8 p-0"
                        style={{
                          height: "20vh",
                          width: "100%",
                          backgroundColor: "#363839",
                          border: "2px white dashed",
                        }}
                      >
                        <form
                          onSubmit={(e) => e.target.preventDefault()}
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <div className="upload-btn-wrapper">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                              <button
                                style={{
                                  backgroundColor: "#363839",
                                  color: "white",
                                  border: "none",
                                  fontSize: "1rem",
                                }}
                              >
                                {Object.keys(selectedFile).length === 0 &&
                                selectedFile.constructor === Object
                                  ? "Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) Recommended size (300x31) pixel"
                                  : selectedFile.name}
                              </button>
                              <i
                                className="fa fa-cloud-upload"
                                aria-hidden="true"
                                style={{
                                  display: "block",
                                  color: "white",
                                  fontSize: "1.7rem",
                                  paddingTop: "10px",
                                }}
                              ></i>
                            </div>
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              name="file"
                              style={{
                                width: "100%",
                                height: "100%",
                                padding: "0",
                                cursor: "pointer",
                              }}
                              onChange={(e) => {
                                uploadBranding(e);
                              }}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Domain Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="name"
                            placeholder="Enter your Domain"
                            value={general.name}
                            onChange={(e) => setAllGeneralValue(e)}
                            required

                          />
                        </div>
                      </div>

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Text Record</label>
                          <textarea
                              style={{height: "55px"}}
                              className="form-control input-dark-accnt personal-info  w-100"
                              name="txt_record"
                              placeholder="Enter Text Record"
                              value={general.txt_record}
                              onChange={(e) => setAllGeneralValue(e)}
                              required
                              title="Copy this record to use for domain verification"
                          >{general.txt_record}</textarea>
                        </div>
                      </div>

                    </div>

                    <div className="row text-right">
                      <div className="col-6"></div>
                      <div className="col-6">
                        <a
                            style={{
                              float: "left",
                              color : "#1cb7f2"
                            }}
                            className="mb-1 pl-xl-2  mt-md-1"
                            title="Edit"
                            data-toggle="collapse"
                            data-parent="#accordion1"
                            href="#showSmtp"
                        >Follow bellow steps to add & verify your domain-</a>

                        <button
                            style={{border: "0px",background: "#1cb7f2"}}
                            className="badge badge-primary"
                            onClick={() => {
                              checkTxt();
                            }}
                            disabled={
                              general.name === "" ||
                              general.txt_record === ""
                                  ? true
                                  : false
                            }
                        >
                          {txtLoad.txt_record ? "Verifying..." : "Verify TXT" }
                        </button>
                      </div>
                    </div>

                    <div className="row justify-content-center collapse in" id="showSmtp">
                      <div className="col-12 p-1">
                        <p>Please use top-level domains only (sub-domains won’t work).</p>
                        <p>Please enter your domain above without any www, http or https (domain.com for example).</p>
                        <p>Enter your domain name for example "mydomain.com" (without the quotation marks) and click the "Next" button.</p>
                        <p>Copy this IP 72.52.158.199 address for the A Record</p>
                        <p>Log into your domain registrar account and find the option to add Host Records, which can usually be found in the Advanced DNS section. If you're struggling to find this, perform a Google search for "[Domain Registrar Here] how to change host records".</p>
                        <p className="note"><b>Adding A Record</b></p>
                        <p>Select your type as "A Record".</p>
                        <p>Set Host as "@".</p>
                        <p>Paste the copied IP Address from step 4 into the value field.</p>
                        <p>Leave TTL as Automatic or 14400.</p>
                        <p>Click Save and you're done.</p>
                        <p className="note"><b>Adding Txt Record</b></p>
                        <p>Copy txt record from above</p>
                        <p>Select your type as "Txt Record".</p>
                        <p>In the Host field, enter @ . Keep the default TTL value.</p>
                        <p>In the TXT Value field, paste the complete domain verification key , you copied earlier</p>
                        <p>Leave TTL as Automatic or 14400.</p>
                        <p>Click Save and you're done.</p>
                        <p className="note"><b>Note: It can take up to 24-72 hours to reflect. Once domain name is propagated and reflected, you can now verify by following below steps</b></p>
                        <p>Copy this txt record</p>
                        <p>Log into your domain host control panel.</p>
                        <p>Click the domain that you need to verify.</p>
                        <p>Access the DNS Management page</p>
                        <p>Click the Manage DNS link in the domain settings page.</p>
                        <p>In the records table, click Add.</p>
                        <p>From the type list, select " TXT "</p>
                        <p>In the Host field, enter @ . Keep the default TTL value.</p>
                        <p>In the TXT Value field, paste the complete domain verification key, you copied earlier</p>
                      </div>
                    </div>

                    <div className="row justify-content-center">

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">App Name </label>
                          <input
                              className="form-control input-dark-accnt personal-info  w-100"
                              type="text"
                              name="app_name"
                              placeholder="Enter your whitelabeled App Name"
                              value={general.app_name}
                              onChange={(e) => setAllGeneralValue(e)}
                              required
                          />
                        </div>
                      </div>

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Title</label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="title"
                            placeholder="Enter your web page title"
                            value={general.title}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>

                    </div>

                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Support Link</label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="support"
                            placeholder="Enter your Support contact link"
                            value={general.support}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Description</label>
                          <textarea
                              style={{height: "55px"}}
                              className="form-control input-dark-accnt personal-info  w-100"
                              name="description"
                              placeholder="Enter your whitelabel App Description"
                              value={general.description}
                              onChange={(e) => setAllGeneralValue(e)}
                              required
                          >{general.description}</textarea>
                        </div>
                      </div>

                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Video Embedded Code</label>
                          <textarea
                              style={{height: "80px"}}
                              className="form-control input-dark-accnt personal-info  w-100"
                              name="embedded_code"
                              placeholder="Enter your video's embedded code"
                              value={general.embedded_code}
                              onChange={(e) => setAllGeneralValue(e)}
                              required
                          >{general.embedded_code}</textarea>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row d-flex justify-content-center align-items-center">
                      <div
                        className="col-12 mx-0 p-0"
                        style={{
                          font: "400 14px/1.19 'Montserrat', Sans-serif",
                          color: "#9e9e9e",
                          textAlign: "center",
                        }}
                      >
                        Color
                      </div>
                      <div
                        className="col-2 mx-0 p-0 mt-2"
                        style={swatch}
                        onClick={() => {
                          setShowColorPicker((value) => ({
                            ...value,
                            open: true,
                          }));
                        }}
                      >
                        <div
                          style={{
                            background: `${
                              general.color === "" ? "white" : general.color
                            }`,
                            height: "100%",
                            borderRadius: "5px",
                            border: "1px solid black",
                          }}
                        />
                       
                    </div> */}

                    <div className="row">
                      <div className="col-10"></div>
                      <div className="col-2 text-right">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                              padding: "10px",
                              height: "auto"
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn"
                            onClick={() => {
                              setGeneralDataInRedux();
                              return history.push(`/temp`);
                            }}
                            style={{
                              padding: "10px",
                              height: "auto"
                            }}
                            disabled={
                              general.logo === "" ||
                              general.name === "" ||
                              general.support === "" ||
                              general.title === "" ||
                              general.txt_record === "" ||
                              general.app_name === "" ||
                              general.description === "" ||
                              txt === false
                                ? true
                                : false
                            }
                          >
                            Next <span style={{color: "#ffffff", fontSize: "16px"}} className="fa fa-angle-double-right"></span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {showColorPicker.open ? (
          <div style={popover} ref={wrapperRef}>
            <div
              style={cover}
              onClick={() =>
                setShowColorPicker((value) => ({ ...value, open: false }))
              }
            />
            <SketchPicker
              color={general.color}
              onChange={handleChangeComplete}
            />
          </div>
        ) : null} */}
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .upload-btn-wrapper .btn {
    background: #171717 !important;
    color: white;
    cursor: pointer;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, {
  removeAlert,
  setAlert,
  getBrandingDataFromServer,
  setGeneralData,
})(WhiteLabel);
