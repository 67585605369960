import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateAvatar } from "../../actions/auth";
import { setLoader, removeLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";
// import Loader from "../loader/Loader";
import styled from "styled-components";
import UploadModal from "../uploadModal/UploadModal";

const AvatarModal = ({
  close,
  updateAvatar,
  auth: { user, loading },
  setLoader,
  removeLoader,
  setAlert,
  loader,
}) => {
  const [user_id, setUserId] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [uploadStuff, setUploadStuff] = useState({
    uploadedPercentage: 0,
    show: false,
  });
  const [sourceCancel, setSourceCancel] = useState({});

  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  const checkedProfilePicture = async (e) => {
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 5) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        setSelectedFile(e.target.files[0]);
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(png, jpeg, jpg)",
          "danger"
        );
        close();
      }
    } else {
      setAlert("You cannot upload more then 5MB in size", "danger");
      close();
    }
  };

  const changeProfile = async (e) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("upload_type", "profile");
    formData.append("user_id", user_id);

    setLoader();

    setUploadStuff((value) => ({ ...value, show: true }));

    await updateAvatar(formData, close, setUploadStuff, setSourceCancel);
  };

  return (
    <AvatarStyle>
      <div
        id="confirm-del"
        className={`modal fade show ${uploadStuff.show ? "" : "d-block"}`}
        style={{ background: "rgba(0,0,0,0.9)" }}
        tabIndex={-1}
        role="dialog"
        data-backdrop="static"
        aria-labelledby="delete-warn"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mx-auto"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close mb-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              style={{ opacity: "1" }}
              onClick={() => {
                removeLoader();
                close();
              }}
            >
              <img
                className="img-fluid"
                src="./assets/images/close.svg"
                alt="Close"
              />
            </button>
            <div className="modal-body bg-white text-center">
              <h4
                className="modal-title mb-2"
                id="delete-warn"
                style={{ color: "#000000" }}
              >
                Upload Avatar
              </h4>

              <div className="w-100 mx-auto mb-3 pb-3 text-center mr-auto">
                <div className="row general_row d-flex justify-content-center search-row">
                  <div className="col-9 search-area">
                    <div
                      className="row d-flex justify-content-around align-items-center"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="col-11 p-2"
                        style={{
                          height: "75%",
                          width: "100%",
                          backgroundColor: "#ffffff",
                          border: "dashed 2px #dddddd",
                        }}
                      >
                        <form
                          onSubmit={(e) => e.target.preventDefault()}
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                            border: "dashed 2px #dddddd",
                          }}
                        >
                          <div className="upload-btn-wrapper">
                            <div>
                              <i
                                className="fa fa-cloud-upload"
                                aria-hidden="true"
                                style={{
                                  display: "block",
                                  color: "#cccccc",
                                  fontSize: "3rem",
                                  paddingTop: "10px",
                                }}
                              ></i>
                              <button
                                style={{
                                  backgroundColor: "white",
                                  color: "#cccccc",
                                  border: "none",
                                  fontSize: "1rem",
                                }}
                              >
                                {Object.keys(selectedFile).length === 0 &&
                                selectedFile.constructor === Object
                                  ? "Upload File (JPG, PNG & JPEG upto 5MB is allowed)"
                                  : selectedFile.name}
                              </button>
                            </div>
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              name="file"
                              style={{
                                width: "100%",
                                height: "100%",
                                padding: "0",
                                cursor: "pointer",
                              }}
                              onChange={(e) => {
                                checkedProfilePicture(e);
                              }}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <form>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    name="file"
                    onChange={(e) => {
                      checkedProfilePicture(e);
                    }}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {Object.keys(selectedFile).length === 0 &&
                    selectedFile.constructor === Object
                      ? "Choose File"
                      : selectedFile.name}
                  </label>
                </div>
              </form> */}
              </div>
              {/* <div className="modal-footer border-top-0 p-0 justify-content-center">
              {loader ? <Loader /> : null}
            </div> */}
              <div className="modal-footer border-top-0 p-0 justify-content-center">
                {loader ? (
                  <button
                    className="btn btn-2 fs-12 text-white align-items-center"
                    type="button"
                    style={{
                      background: "#1cb7f2",
                      font: "16px",
                      paddingTop: "-15px",
                      height: "3rem",
                    }}
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm text-light mx-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <strong>Uploading Profile...</strong>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-2 fs-12"
                    data-dismiss="modal"
                    disabled={selectedFile.name ? false : true}
                    onClick={() => {
                      changeProfile();
                    }}
                  >
                    Upload Profile
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {uploadStuff.show ? (
        <UploadModal
          show={setUploadStuff}
          percentage={uploadStuff.uploadedPercentage}
          sourceCancel={sourceCancel}
          close={close}
          removeLoader={removeLoader}
          heading={"Updating Avatar..."}
          desc={"Your profile pic is being updated to new."}
        />
      ) : null}
    </AvatarStyle>
  );
};

const AvatarStyle = styled.div`
  .search-area {
    background: white !important;
    height: 100% !important;
  }
  .search-row {
    height: 15rem !important;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .upload-btn-wrapper .btn {
    background: white !important;
    color: black;
    cursor: pointer !important;
  }
`;

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});

AvatarModal.propTypes = {
  close: PropTypes.func.isRequired,
  updateAvatar: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  removeLoader: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updateAvatar,
  setLoader,
  setAlert,
  removeLoader,
})(AvatarModal);
