import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function Alert({ alert: { message, type }, authStyle }) {
  return !message ? (
    <></>
  ) : (
    <div
      className={`alert alert-${type} mb-5 font-weight-bold border border-${type}`}
      style={
        authStyle
          ? {
              width: "100%",
            }
          : {
              position: "fixed",
              top: "75px",
              width: "85%",
              textAlign: "center",
              borderWidth: "3px",
              left: "7.5%",
              zIndex: "2",
            }
      }
    >
      {message}
    </div>
  );
}

Alert.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps)(Alert);
