import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {connect, useSelector} from "react-redux";
import { Redirect } from "react-router-dom";
import WhiteLabelNav from "./WhiteLabelNav";
import { useHistory } from "react-router-dom";
import { setPaymentData } from "../../actions/whiteLabel";

const Payment = ({ user, loading, whiteLabel, setPaymentData }) => {

  const auth = useSelector(state => state.auth.user);
  const [accountType, setAccountType] = useState([]);
  const [allPaymentOption, setAllPaymentOption] = useState([]);
  const [selectPayment, setSelectPayment] = useState("-Select-");
  const [paymentInputData, setPaymentInputData] = useState([
    { button_code: "", type: "-Select-" },
    { button_code: "", type: "bronze" },
    { button_code: "", type: "silver" },
    { button_code: "", type: "gold" },
  ]);

  const [paymentCode,setPaymentCode] = useState();

  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (whiteLabel.paymentMethods.length > 0) {
      setAllPaymentOption(whiteLabel.paymentMethods);
      // setSelectPayment(whiteLabel.paymentMethods[0].name);
    }else if(whiteLabel.whiteLabelData.length > 0){
      setAllPaymentOption(whiteLabel.whiteLabelData[0].payment);
    }
  }, [whiteLabel.paymentMethods, whiteLabel.loading]);

  useEffect(() => {
    if (whiteLabel.whiteLabelData.length > 0) {
      setSelectPayment(whiteLabel.whiteLabelData[0].payment[0].payment);
    }
  }, [whiteLabel.whiteLabelData]);

  useEffect(()=>{
    if (whiteLabel.whiteLabelData.length > 0) {
      setPaymentCode(whiteLabel.whiteLabelData[0].payment);
    }
  },[]);

  useEffect(() => {
    if(whiteLabel.payment.length > 0) {
      whiteLabel.payment.length > 0 &&
      whiteLabel.payment.map((val) =>
          setPaymentInputData((pay) =>
              pay.map((payData) => {
                if (payData.type === val.type) {
                  return {...payData, button_code: val.button_code};
                } else {
                  return {...payData};
                }
              })
          )
      );
    }else if(whiteLabel.whiteLabelData.length > 0){
      whiteLabel.whiteLabelData.length > 0 &&
      whiteLabel.whiteLabelData[0].payment.map((val) =>
          setPaymentInputData((pay) =>
              pay.map((payData) => {
                if (payData.type) {
                  return {...payData, button_code: val.button_code};
                } else {
                  return {...payData};
                }
              })
          )
      );
    }
  }, [whiteLabel.payment,whiteLabel.whiteLabelData]);

  console.log(selectPayment);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  const sendPaymentData = () => {
    let data = [];

    whiteLabel.price.length > 0 &&
      whiteLabel.price.map((val) =>
        paymentInputData.map((pay) => {
          if (pay.type === val.type) {
            data = [
              ...data,
              { ...pay, payment: selectPayment, mem_id: val.id },
            ];
          }
        })
      );

    setPaymentData(data);
  };


  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"payment"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Payment
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-10">
                    {/*<div className="row justify-content-center">*/}
                    {/*  <div className="col col-lg-5 col-12 p-1">*/}
                    {/*    <div className="form-group form-group-mb">*/}
                    {/*      <label className="input-label">Payment Mode</label>*/}
                    {/*      <select*/}
                    {/*        className="form-control input-account-type p-accnt"*/}
                    {/*        id="Account_selector"*/}
                    {/*        name="voice"*/}
                    {/*        value={selectPayment}*/}
                    {/*        onChange={(e) => {*/}
                    {/*          setSelectPayment(e.target.value);*/}
                    {/*        }}*/}
                    {/*      >*/}
                    {/*        <option value="-Select-" id="yt_video">*/}
                    {/*          -Select-*/}
                    {/*        </option>*/}
                    {/*        {allPaymentOption.length > 0*/}
                    {/*          ? allPaymentOption.map((payment) => {*/}
                    {/*              return (*/}
                    {/*                <option*/}
                    {/*                  value={payment.name}*/}
                    {/*                  key={payment.id}*/}
                    {/*                >*/}
                    {/*                  {payment.title}*/}
                    {/*                </option>*/}
                    {/*              );*/}
                    {/*            })*/}
                    {/*          : null}*/}
                    {/*      </select>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row">*/}
                    {/*  <div className="col-11">*/}
                    {/*    {*/}
                    {/*      selectPayment != '-Select-' ? <a href={`#show${selectPayment}`} data-toggle="collapse" data-parent="#accordion1" style={{color : "#1cb7f2"}}><b>Obtain {selectPayment} Button Code -</b></a> : ''*/}
                    {/*    }*/}
                    {/*  </div>*/}
                    {/*  <div className="col-11" style={{marginBottom: "1rem"}}>*/}
                    {/*    {*/}
                    {/*      selectPayment === 'Warrior Plus' ?*/}
                    {/*      <div className="collapse in" id="showWarrior Plus" style={{marginTop: "20px"}} >*/}
                    {/*        <p>From within your Warrior Plus account, click "Vendors" on your top navigation.</p>*/}
                    {/*        <p>On the next screen, click "Offers."</p>*/}
                    {/*        <p>Select your Offer.</p>*/}
                    {/*        <p>Click the "Get Code" button.</p>*/}
                    {/*        <p>Adjust the style of the button as desired (optional).</p>*/}
                    {/*        <p>Copy the button code.</p>*/}
                    {/*      </div> : ''*/}
                    {/*    }*/}
                    {/*    {*/}
                    {/*      selectPayment === 'Jvzoo' ?*/}
                    {/*      <div className="collapse in" id="showJvzoo" style={{marginTop: "20px"}}>*/}
                    {/*        <p>From within your JVZoo account, navigate to your Sellers dropdown and click "Seller's Dashboard."</p>*/}
                    {/*        <p>On the next screen, click "Actions" next to your product.</p>*/}
                    {/*        <p>Click "Buy Buttons."</p>*/}
                    {/*        <p>Choose a button and select "Embed Code."</p>*/}
                    {/*        <p>Copy the HTML Code.</p>*/}
                    {/*      </div> : ''*/}
                    {/*    }*/}
                    {/*    {*/}
                    {/*      selectPayment === 'Pksipn' ?*/}
                    {/*      <div className="collapse in" id="showPksipn" style={{marginTop: "20px"}}>*/}
                    {/*        <p>Click on the “Funnels” at the left navigation menu.</p>*/}
                    {/*        <p>Click on the Blue “Manage” icon of the specific funnel you are using the pop-up with.</p>*/}
                    {/*        <p>Click on the “Checkout options ” icon for the specific your product in the Funnel.</p>*/}
                    {/*        <p>Enable the pop-up widget in the Checkout options.</p>*/}
                    {/*        <p>Select your widget design.</p>*/}
                    {/*        <p>Next, copy the Embed Code of the Widget provided and paste it anywhere below the head tag of your webpage. This embed code is needed to allow the pop-up to function correctly.</p>*/}
                    {/*        <p>Finally, link the “Buy Now” button on your sales page to the Widget.</p>*/}
                    {/*      </div> : ''*/}
                    {/*    }*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {whiteLabel.price.length > 0 &&
                      whiteLabel.price.map((value, index) => (
                        <div
                          key={index}
                          className="row justify-content-center align-items-start"
                        >
                          <div className="col-4 text-white" style={{ fontSize: "1.2rem" , marginTop: "25px"}}>
                            Redirect URL :
                          </div>
                          <div className="col-8 p-1">
                            <div className="form-group form-group-mb">
                              <label className="input-label">
                                Redirect URL
                              </label>
                              <input
                                  className="form-control input-dark-accnt personal-info  w-100"
                                  type="text"
                                  name="secret_key"
                                  placeholder="Enter Secret Key"
                                  value={`https://v2server.videoreel.io/inp?id=${auth.user_id}`}
                                  required
                                  title="Copy this URL to use while generating button code"
                                  readOnly
                              />
                            </div>
                          </div>
                          <div
                            className="col-4 text-white"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {value.name} (Button Code) :
                          </div>
                          <div className="col-8 p-1">
                            <div className="form-group form-group-mb">
                              <textarea
                                className="form-control input-dark-accnt personal-info"
                                name="text"
                                placeholder="Paste Your Button code here"
                                value={
                                  paymentInputData.find(
                                    (data) => value.type === data.type
                                  ).button_code
                                }
                                onChange={(e) => {
                                  const target = e.target;
                                  setPaymentInputData((pay) =>
                                    pay.map((val) =>
                                      val.type === value.type
                                        ? { ...val, button_code: target.value }
                                        : { ...val }
                                    )
                                  );
                                }}
                                autoComplete="off"
                                required
                                style={{
                                  padding: "10px",
                                  height: "8rem",
                                }}
                              ></textarea>
                            </div>
                          </div>

                        </div>
                      ))}


                    <div className="row justify-content-center">
                      <div className="col-10"></div>
                      <div className="col-2 text-center mt-3">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                              padding: "10px",
                              height: "auto"
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            onClick={() => {
                              sendPaymentData();
                              return history.push(`/smtp`);
                            }}
                              style={{
                              padding: "10px",
                              height: "auto"
                            }}

                          >
                            Next <span style={{color: "#ffffff", fontSize: "16px"}} className="fa fa-angle-double-right"></span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, { setPaymentData })(Payment);
