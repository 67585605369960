import React, {useEffect, useState} from "react";
import './Resizable.css';
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/images/delete.svg";
import Track from "./Track";
import {ActionCreators as UndoActionCreators} from "redux-undo";
import {
	applyObjectDelete,
	applyObjectRearrange,
	applyTextObjectProperties
} from "../../../../../../../actions/normalTemplateActions";
import {connect} from "react-redux";
function Tracks(props) {
	const [object, setObject] = useState(props.object);

	useEffect(() => {
		setObject(props.object)
	},[props.object])

	const handleDelete = (e) => {
		e.preventDefault();
		const delObject = {
			id:object.id,
			trackId:object.trackId
		}
		props.deleteObject(delObject);
	}

	const handleChange = (e) => {
		const value = e.target.value;
		props.applyTextObjectProperties({
			id:object.id,
			type:'volume',
			value:e.target.value
		});
	}
	return (
		<div className="timeline-component-track" id="track_id_">
			<div className="timeline-track">
				{object.objectType === 'audio' ? <div>
						<span className="track-title">Delete</span>
						<div className="track-btns">
							<a href="/" className="ico icon-delete" onClick={(e) => handleDelete(e)}>
								<svg width="12px" height="12px" viewBox="0 0 12 12">
									<DeleteIcon />
								</svg>
							</a>
						</div>

					<span className="track-title">Volume</span>
					<div className="volume-wrapper">
						<input className="volume form-control-range" type="range" name="volume" step="0.1" min="0"
							   max="1" onChange={(e) => handleChange(e)} value={object.volume}/>
					</div>
				</div> :
					<div>
						<span className="track-title">Delete</span>
						<div className="track-btns">
							<a href="/" className="ico icon-delete" onClick={(e) => handleDelete(e)}>
								<svg width="12px" height="12px" viewBox="0 0 12 12">
									<DeleteIcon />
								</svg>
							</a>
						</div>
					</div>
				}

			</div>
			<div className="timeline-track-layer track-layer-1">
				<Track object={object}/>
			</div>
		</div>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		deleteObject: (newObject) => dispatch(applyObjectDelete(newObject)),
		applyTextObjectProperties: (newObj) => dispatch(applyTextObjectProperties(newObj))
	}
}

export default connect(null, mapDispatchToProps)(Tracks)